import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "React Testing Library internal elements",
  "author": "tzookb",
  "type": "post",
  "date": "2019-07-28T16:53:29.000Z",
  "draft": true,
  "url": "/react-testing-library-internal-elements",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["javascript"],
  "tags": ["javascript", "react", "testing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{}}>{`import { render } from '@testing-library/react';

  it.only('call filtered items after invoice deletion', async () =&gt; {
    const props = {
      deleteInvoiceApiActionCreator: jest.fn(),
      getAllInvoicesWithFilters: jest.fn(),
    }
    const { container } = render(&lt;TestedComponent {...props} /&gt;)

    const td = container.querySelector('table tbody tr td:last-child');
    const deleteIcon = td.querySelector('button');

    fireEvent.click(deleteIcon);

    const actionButtons = td.querySelectorAll('button');
    const deleteButton = actionButtons[0];
    
    await fireEvent.click(deleteButton);

    expect(props.deleteInvoiceApiActionCreator).toHaveBeenCalled();
    expect(props.getAllInvoicesWithFilters).toHaveBeenCalled();
  });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      